<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_4" x1="149.18" x2="28.92" y1="69.31" y2="113.08">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <g id="icon-ideeënbox">
      <rect :fill="background" height="183" width="183"/>
      <path d="M138.53,99.39a4,4,0,0,0-2.68-4.2l-23.07-7.91a28.57,28.57,0,0,1-6.16,6.21,4,4,0,0,0,.91.44l15.79,5.42L89.6,111,56.14,99.45l15.62-6.21a28.42,28.42,0,0,1-6-6.23L43.42,95.9l-.07,0-.19.08-.29.13-.27.14-.36.24-.24.2a4,4,0,0,0-.84,1L41,98q-.06.13-.12.3a1.55,1.55,0,0,0-.08.23l0,.09-.06.24a.36.36,0,0,0,0,.1,2.93,2.93,0,0,0,0,.29,3.71,3.71,0,0,0,0,.47v44.86a4,4,0,0,0,2.69,3.78l45,15.5.09,0a3.11,3.11,0,0,0,.41.11l.19,0a4.32,4.32,0,0,0,.62.05,4.18,4.18,0,0,0,.61-.05l.19,0a3.11,3.11,0,0,0,.41-.11l.09,0,45-15.5a4,4,0,0,0,2.69-3.78V99.71A1.72,1.72,0,0,0,138.53,99.39ZM85.6,154.44l-37-12.73V105.32l37,12.74Zm3.76-117a4,4,0,0,1-4-4V23a4,4,0,0,1,8,0V33.43A4,4,0,0,1,89.36,37.43ZM118,45.68a4,4,0,0,1-2.83-6.83l7.41-7.41a4,4,0,1,1,5.66,5.65l-7.42,7.41A3.94,3.94,0,0,1,118,45.68Zm-54.92-.84a4,4,0,0,1-2.83-1.17l-7.41-7.41a4,4,0,0,1,5.65-5.66L65.9,38a4,4,0,0,1-2.83,6.83ZM143.6,69.7H133.12a4,4,0,0,1,0-8H143.6a4,4,0,0,1,0,8Zm-93.72,0H39.4a4,4,0,1,1,0-8H49.88a4,4,0,1,1,0,8Zm64.25.21A24.66,24.66,0,1,0,82.22,93.47v5.34a7.5,7.5,0,0,0,15,0V93.56a2.09,2.09,0,0,0,0-.25A24.63,24.63,0,0,0,114.13,69.91Zm-15,13.82-4.91,3.85-1.7,1.33v3.75a3,3,0,0,1-2.8,3h-.2a3,3,0,0,1-2.41-1.22,2.92,2.92,0,0,1-.24-.37,3.07,3.07,0,0,1-.35-1.41v-4l-1.31-1L80.3,83.73A3,3,0,0,1,84,79l5.7,4.48L95.42,79a3,3,0,1,1,3.71,4.72Zm6.48-15.22a3.14,3.14,0,0,1-1.07.19,3,3,0,0,1-2.81-1.93,15.28,15.28,0,0,0-3-4.89,19.15,19.15,0,0,0-5.32-3.47,3,3,0,1,1,2.41-5.49,25.21,25.21,0,0,1,7.07,4.63l.15.16a21.06,21.06,0,0,1,4.32,6.92A3,3,0,0,1,105.61,68.51Z" id="icon-idee" style="fill:url(#Naamloos_verloop_4);"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>